export const Navigation = {

    $links: document.querySelectorAll('nav .main a'),
    $btnMenu: document.querySelector('.btn-menu'),
    $body: document.querySelector('body'),

    // Set during initialization
    $sections: [],

    setActiveLink($activeLink = null) {
        Navigation.resetActiveLink()
    
        if ($activeLink) {
            $activeLink.classList.add('active')   
        }
    },

    resetActiveLink() {
        Navigation.$links.forEach($link => {
            $link.classList.remove('active')
        })
    },

    getLinkByHref(href) {
        let $links = Array.from(Navigation.$links).filter($link => $link.getAttribute('href') === href)
        return ($links.length > 0) ? $links[0] : null
    },

    onLinkClick(e) {
        Navigation.$body.classList.remove('menu-is-open')
    },

    onMenuButtonClick(e) {
        Navigation.$body.classList.toggle('menu-is-open')
    },

    init() {
        // Setup click listener
        Navigation.$links.forEach($link => {
            $link.addEventListener('click', (e) => {
                Navigation.onLinkClick(e)
            })
        })
        Navigation.$btnMenu.addEventListener('click', (e) => {
            Navigation.onMenuButtonClick(e)
        })
    }
}

export default Navigation